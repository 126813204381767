import React from 'react'
import ButtonBlock from '../ButtonBlock'
import BASE_URL from '../../data/constants'

const SectionPromoItems = () => {
  return (
      <>
        <section className="section section-light section-promo-items">
        <div className='container'>
              <div className="row">
                    <div className="col-12">
                        <div className="section-img">
                <img src={ `${BASE_URL}/assets/img/maktub-logo-b.png`} alt='Faridah - Maktub' />
                        </div>
                    </div>            
              </div>
              <div className='row'>
                  <div className="col-10 product-item-img"></div>
                  <div className="col-2 product-item-btn">
                    <ButtonBlock
                      type="button"
                      title="SHOP NOW"
                      cls="btn btn-secondary-dark"
                      link="/"
                      name="buy_now"
                    />
                  </div>
              </div>
          </div>
          </section>
      </>
  )
}

export default SectionPromoItems 