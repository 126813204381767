import React from 'react'
import ButtonBlock from '../ButtonBlock';

const SectionProducts = () => {
  return (
      <>
          <section className="section section-light section-products">  
          <div className='container'>
              <div className="row">
                  <div className="col-6">
                      <h3>EARTH . FIRE . WATER . AIR</h3>
                      <p>
                      Faridah perfume collection with an entirely original formula featuring stellar, sustainable natural ingredients. A fragrance that smells as delicious as sun-kissed skin.
                      </p>
                  </div>
                  <div className="col-6">
                      <h1 className='section-heading'>Fragrances.</h1>
                  </div>
              </div>
              <div className="row">
                  <div className="col-3 product-block">
                      <div className="item-box">
                          <div className="item-info">
                              <label>Earth</label>
                              <span>A classic choice for unisex</span>
                          </div>
                          <div className="item-img" id="item-earth">
                                    <ButtonBlock
                                    type="button"
                                    cls="btn btn-primary-dark btn-small"
                                    title="BUY"
                                    name="buy_now"
                                    link="/"
                                    />
                          </div>
                      </div>
                  </div>
                  <div className="col-3 product-block">
                      <div className="item-box">
                          <div className="item-info">
                              <label>Air</label>
                              <span>A classic choice for unisex</span>
                          </div>
                          <div className="item-img" id="item-air">
                              <ButtonBlock
                                    type="button"
                                    cls="btn btn-primary-dark btn-small"
                                    title="BUY"
                                    name="buy_now"
                                    link="/"
                                    />
                          </div>
                      </div>
                  </div>
                  <div className="col-3 product-block">
                      <div className="item-box">
                          <div className="item-info">
                                <label>Fire</label>
                                <span>A classic choice for unisex</span>
                          </div>
                          <div className="item-img" id="item-fire">
                              <ButtonBlock
                                    type="button"
                                    cls="btn btn-primary-dark btn-small"
                                    title="BUY"
                                    name="buy_now"
                                    link="/"
                                    />
                          </div>
                      </div>
                  </div>
                  <div className="col-3 product-block">
                      <div className="item-box">
                          <div className="item-info">
                                <label>Water</label>
                                <span>A classic choice for unisex</span>
                          </div>
                          <div className="item-img" id="item-water">
                              <ButtonBlock
                                    type="button"
                                    cls="btn btn-primary-dark btn-small"
                                    title="BUY"
                                    name="buy_now"
                                    link="/"
                                    />
                          </div>
                      </div>
                  </div>
              </div>
              </div>
              </section>
      </>
  )
}

export default SectionProducts