import React from 'react'
import HeroSection from '../components/sections/HeroSection'
import SectionMain from '../components/sections/SectionMain'
import SectionBanner from '../components/sections/SectionBanner'
import SectionProducts from '../components/sections/SectionProducts'
import SectionPromoItems from '../components/sections/SectionPromoItems'
import SectionHighlightItems from '../components/sections/SectionHighlightItems'

const Home = () => {
  return (
    <>
      <HeroSection />
          <SectionMain />
          <SectionProducts />
          <SectionBanner />
          <SectionPromoItems />
          <SectionHighlightItems />
    </>
  )
}

export default Home