import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <>
    <footer className='footer'>    
        <div className="container">
          <div className="row">
            <div className="col-8 footer-left">
                <div className="footer-logo-container">
                    <img src="assets/img/logo.png" alt="Faridah" className="footer-logo" />                      
                </div>
                <div className="footer-address-container">
              <h3>Let's Meet</h3>
                <ul className="footer-address">
                  <li>Villa No. 3, Dar Al Ber Society</li>
                  <li>1017 B, PO Box 121457, Dubai, UAE.</li>
                  <li>Phone: +971 3933551</li>
                  <li>hello@faridah.com</li>
                </ul>
              </div>
              <div className="social-links-container">
                <ul className="social-links">
                    <li>
                    <Link to="#" className='social-link-item'>VIMEO</Link>
                    </li>
                    <li>
                    <Link to="#" className='social-link-item'>INSTAGRAM</Link>
                    </li>
                    <li>
                    <Link to="#" className='social-link-item'>FACEBOOK</Link>
                    </li>
                </ul>
              </div>
            </div>
            <div className="col-4 footer-right">
            <h1>ENTER THE WORLD OF PERFUMES.</h1>
              <form action="#" className="footer-form">
                <input type="hidden" name="form-title" value="Contact Form" />
                <input type='text' name="user-name" placeholder='Name' className='form-input'/>
                <input type='phone' name="user-phone" placeholder='Phone' className='form-input' />
                <input type='email' name="user-email" placeholder='Email' className='form-input'/>
                <textarea name="user-comments" value="" placeholder='Comments' className='form-textarea'></textarea>
                <button type="submit" name="form-send" className='btn btn-primary'>SEND</button>
              </form>
            </div>
          </div>
          <div className="row copyright">
            <div className="col-12">
            © 2023 Faridah. All rights reserved. Powered by <Link to="https://www.performena.com" className='footer-link-item'>Performena</Link>. <Link to="#" className='footer-link-item'>Privacy Policy</Link>
            </div>
          </div>
      </div>
    </footer>
    </>
  )
}

export default Footer 