import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import BASE_URL from '../data/constants'

const NavBar = () => {

    const [click, setClick] = useState(false);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    
    return (
     <nav className="navbar">
          <div className="navbar-container">
                <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                <img src={`${BASE_URL}/assets/img/logo.png`} alt="Farida" className="navbar-logo" /> 
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                      <i className={ click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Home</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Originals</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Bio</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Shop</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Services</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Stories</Link></li>
                    <li className="nav-item"><Link to="/" className='nav-link' onClick={closeMobileMenu}>Contact</Link></li>
                </ul>    
          </div>        
     </nav>
  )
}

export default NavBar