import React from 'react'
import { Link } from 'react-router-dom'

const ButtonBlock = (props) => {
  return (
      <>
          <div className="button-container">
              <Link to={ props.link}>
              <button type={props.type}
                  className={props.cls}
                  name={props.name}>
                  { props.title}
                  </button>
                  </Link>
        </div>
      </>
  )
}

export default ButtonBlock