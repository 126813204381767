import React from 'react'
import BASE_URL from '../../data/constants'

const HeroSection = () => {
  return (
    <section className='hero-home' id="hero">
        <video src={`${BASE_URL}/assets/media/faridah_promo.mp4`} autoPlay loop muted className="hero-brand-promo"></video>        
    </section>
  )
}

export default HeroSection