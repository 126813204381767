import React from 'react';
import ButtonBlock from '../ButtonBlock';

const SectionMain = () => {
  return (
    <>
      <section className="section section-light section-main">
        <div className='container'>
            <div className="hero-text">
            <h3>
                Nurturing
                Passion...
            </h3>
            <div className='spacer'></div>
            <div className='spacer'></div>
            <ButtonBlock
              type="submit"
              cls="btn btn-secondary-light"
              title="SHOP NOW"
              name="buy_now"
              link="/"
            />
            </div>      
        </div>
        </section>
      </>
  )
}

export default SectionMain;