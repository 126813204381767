import React from 'react'
import ButtonBlock from '../ButtonBlock'
import BASE_URL from '../../data/constants'
const SectionBanner = () => {
  return (
      <>
        <section className="section section-light section-banner">  
            <div className="container">
                  <div className="row">                      
                    <div className="col-6">
                    <div className="section-img">
                <img src={`${BASE_URL}/assets/img/maktub-logo-w.png`} alt='Faridah - Maktub' />
                    </div>      
                    </div>
                    <div className="col-6">
                    <div className="section-info">
                    Maktub which means "It is written" It's a belif about destiny. These fragrances have each been curated to invoke the motions that we associate with walking a predetermined path.
                    </div>      
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-12 btn-bottom">
                          <ButtonBlock
                              link="/"
                              type="button"
                              cls="btn btn-secondary-light"
                              name="buy-now"
                              title="Buy Now"
                          />
                      </div>
                  </div>
            </div>
        </section>
      </>
  )
}

export default SectionBanner