import React, { useState } from 'react';
import NavBar from './NavBar';
import '../App.css';

const Header = () => {
  const [fixHeader, setFixHeader] = useState(false);

  function setHeader() { 
    if (window.scrollY >= 100) {
      setFixHeader(true);
    } else { 
      setFixHeader(false);
    }
  }

  window.addEventListener("scroll", setHeader);

  return (
    <>
    <header className={ fixHeader ? 'header fixed' : 'header'} id="site-header">
       <NavBar />
      </header>
    </>
  )
}

export default Header 