import React from 'react'
import ButtonBlock from '../ButtonBlock'
import BASE_URL from '../../data/constants'

const SectionHighlightItems = () => {
  return (
      <>
        <section className="section section-light section-highlight-items">  
        <div className='container'>
              <div className="row">
                  <div className="col-12">
              <img src={ `${BASE_URL}/assets/img/logo.png`} alt='Faridah' className='section-img' />
                  </div>
              </div>
              <div className="row">
                    <div className="col-12">
                        <div className="special-product-item">                        
                            <ButtonBlock
                              type="button"
                              title="SHOP NOW"
                              cls="btn btn-primary-dark"
                              link="/"
                              name="buy_now"
                            />
                        </div>                      
                    </div>
              </div>      
              </div>
              </section>
      </>
  )
}

export default SectionHighlightItems